import React from "react"
// import { Link } from "gatsby"
import { tableDataMapper } from "../../prismic/helpers"

import {
  HeadingBanner,
  QuickStats,
  ContentTable,
  SubscriptionPanel,
} from "./../shared"

const ContentWithTable = ({
  banner_image = {},
  main_buttons = [],
  page_heading = {},
  stats_heading = {},
  stats_items = [],
  table = [],
}) => {
  const [tableHeader, tableBody] = tableDataMapper(table)
  return (
    <>
      <HeadingBanner
        heading={page_heading}
        buttons={main_buttons}
        banner_image={banner_image}
      />
      <QuickStats heading={stats_heading} items={stats_items} />
      <ContentTable header={tableHeader} body={tableBody} />
      <SubscriptionPanel />
    </>
  )
}

export default ContentWithTable
