export function tableDataMapper(table = []) {
  const tableHeader = []
  const tableBody = []
  table.forEach((data, index) => {
    if (index === 0) {
      data.items.map(item => {
        tableHeader.push(item.col[0].text)
        return tableHeader;
      })
    } else {
      const body = []
      data.items.map(item => {
        body.push(item.col[0].text);
        return body;
      })
      tableBody.push(body)
    }
    return true
  })

  return [tableHeader, tableBody]
}
