import React from "react"
import { connect, useSelector } from "react-redux"
import { IndicesQuery } from "../../prismic/staticQueries"

import Layout from "../../components/layout"
import ContentWithTable from "../../components/content-basic/content-with-table"

const Indices = () => {
  const language = useSelector(state => state.language)
  const IndicesData = IndicesQuery(language)

  return (
    <Layout>
      <ContentWithTable {...IndicesData} />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })
export default connect(mapStateToProps)(Indices)
